import EventCard from "./EventCard";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/nl';

dayjs.extend(utc); 
dayjs.extend(timezone); 

const EventItem = ({eventsGroupData, currentCity}) => {
  const today = dayjs.utc().locale('nl').format('D MMMM');
  const dayName = eventsGroupData.date === today ? 'Vandaag' : eventsGroupData.day;
  const date = eventsGroupData.date;
  const events = eventsGroupData.data;

  return (
    <div className="tab">
        <div className="tab-header">
          <div className="row justify-content-between text-white">
            <div className="days">
              <h3 className="donderdag">{dayName}</h3>
            </div>
            <div className="date">
              <h5 className="date">{date}</h5>
            </div>
          </div>
        </div>
        <div className="tab-body">
          <div className="event-box">
            <div className="row">
            {events.map(event => (
              <EventCard
                key={event.EventId} 
                eventCardData={event}
                singleEvent={events.length === 1}
                currentCity={currentCity}
              />
            ))}
            </div>
          </div>
        </div>
      </div>
  )
}

export default EventItem;