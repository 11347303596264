import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { useCookies } from 'react-cookie';

import './App.css';
import './styles/media-query.css';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import SingleEvent from './pages/single-event/SingleEvent';
import ThanksPage from './pages/thanks/thanksPage';
import Footer from './layout/footer/Footer';
import ScrollToTop from './helpers/ScrollTop';
import { API_URL } from './utils/config';

function App() {
  const [cookie] = useCookies(['token']);
  const token = cookie.token;
  const { pathname } = useLocation();
  // const [isValidToken, setIsValidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);



  const UserGuard = ({ token }) => {
    const location = useLocation();
    console.log('UserGuard token', token)

    useEffect(() => {
      async function handleTokenValid(token) {
        const data = {
          token: token,
        };
        try {
          const result = await fetch(`${API_URL}read.php`, {
            mode: 'cors',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });
          const resData = await result?.json();
          if (Array.isArray(resData?.items)) {
            return true
          } else {
            return false
          }
        } catch (err) {
          console.error('Error===>', err);
          return false;
        }
      }
      if (isValidToken) return
      handleTokenValid(token).then((isValid) => {
        console.log('isValid', isValid)
        setIsValidToken(isValid);
        setIsLoading(false); // Mark loading as complete
      });
    }, [token]);

    console.log('isValidToken', isValidToken, 'isLoading', isLoading, 'token', token)

    if (isLoading) {
      return null; // Render nothing while loading
    }

    return isValidToken ? (
      <Outlet />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  };

  return (
    <ChakraProvider theme={'dark'}>
      <ScrollToTop />
      <Routes>
        {(!token || !isValidToken) && (
          <Route
            path="/"
            element={<Navigate to="/login" replace />}
          />
        )}
        <Route path="login" element={<Login setIsValidToken={setIsValidToken} isValidToken={isValidToken} />} />
        <Route path="/" element={<UserGuard token={token} />}>
          <Route index element={<Home token={isValidToken ? token : null} />} />
          <Route path="single-event" element={<SingleEvent token={token} />} />
          <Route path="thanks" element={<ThanksPage />} />
                 </Route>
      </Routes>
      {(!token || !isValidToken) || pathname === '/login' ? null : <Footer token={token} />}
    </ChakraProvider>
  );  
}

export default App;
