import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LogoSvg } from '../../utils/svg';
import ArrowImg from '../../assets/image svg/arrow-left.svg';
import popupImg from '../../assets/image svg/Illustration.svg';
import './thanksPage.css';

const ThanksPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    if (location.state !== null) {
      const storage = JSON.parse(localStorage.getItem('allEvents'));
      const getPageData = storage.filter(
        item => item.EventId === location.state
      );
      setEventData(getPageData[0]);
    } else {
      // navigate('/');
      console.log('THANK YOU PAGE ERROR');
    }
  }, []);

  const handleBackClick = e => {
    e.preventDefault();
    navigate(`/`);
  };

  const eventCategory = eventData.EventCategorie;

  const msgHeader =
    eventCategory !== 'Community ticket'
      ? 'Jouw naam staat op de lijst'
      : 'Hier is je community ticket!';
  const msgText =
      eventCategory !== 'Community ticket'
        ? 'Je ontvangt een e-mail van ons met een bevestiging'
        : 'Je ontvangt een e-mail van ons met een bevestiging';
  const msgBtn =
    eventCategory !== 'Community ticket'
      ? 'Terug naar home'
      : 'Terug naar home';

  if (eventData.length < 1) {
    return <div className="hero-section-single thanksPage" style={{ minHeight: '100dvh' }}></div>;
  }

  return (
    <div>
      <section class="hero-section-single thanksPage">
        <div class="container-single">
        <div className="row">
            <div className="arrow">
              <a href="/" 
              onClick={handleBackClick}
              >
                <img src={ArrowImg} alt="arrow" className="logo" />
              </a>
            </div>
            <div className="logo-Img">
              <a href="/">
                <LogoSvg />
              </a>
            </div>
          </div>
        </div>
        {eventData.length !== 0 ? (
        <div class="popup-content-box">
          <div class="popup-content">
            <img src={popupImg} class="Illustration" />
            <p class="Hier">{msgHeader}</p>
            <p class="Je">{msgText}</p>
            <a class="Terug" onClick={handleBackClick}>
              {msgBtn}
            </a>
          </div>
        </div>
      ) : null}
      </section>

    </div>
  );
};

export default ThanksPage;
