import { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import {
  StarSvg,
  TrendingCheckSvg,
  TrendingStarSvg,
} from '../utils/svg';
import SearchItem from './SearchItem';
import EmptySearchResult from './EmptySearchResult';
// import './Home.css';
const SearchBar = ({ eventData,formattedCurrentDate }) => {
  const [searchForm, setSearchForm] = useState(false);
  const [smSearchForm, setSmSearchForm] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchMobile = useRef(null);

  const title = searchValue.length < 1 ? 'Trending': 'Search results:'

  const trendingData = eventData.filter(event => event.Trending === 'Yes') || [];
  const filteredData = eventData.filter(event => event.EvenName.toLowerCase().includes(searchValue.toLowerCase()));


  if (!trendingData || trendingData.length === 0) {
    console.log('loading...')
  }

  const searchInformationBoxRef = useRef(null);
  const searchModalBoxRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchInformationBoxRef.current &&
        !searchInformationBoxRef.current.contains(event.target) &&
        searchForm
      ) {
        setSearchForm(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [searchForm]);

  const handleSearchFormFocus = () => {
    // Додано затримку в 100 мілісекунд
    setTimeout(() => {
      setSearchForm(true);
      setSmSearchForm(true);
      searchMobile.current.focus();
    }, 100);
  };

  return (
<>
<form className="hero-form">
  <button type="button">
    <StarSvg />
  </button>
  <input
    type="text"
    placeholder="Zoek een evenement, clubavond of festival"
    autoComplete="off"
    name="search"
    id="showModalBtnbottom"
    onFocus={handleSearchFormFocus}
    // onBlur={() => setSearchForm(false)}
    onClick={handleSearchFormFocus}
    onChange={(e) => setSearchValue(e.target.value)}
  />
</form>
<div
ref={searchInformationBoxRef}
  className={clsx(
    'search-information-box destop',
    searchForm ? 'active' : ''
  )}
>
  <div className="main-box">
    <h6 className='search-title'>{title}</h6>
    {searchValue === '' ? (
            trendingData.map((item, index) => (
              <SearchItem key={item.EventId} searchItem={item} isEven={index % 2 === 0} formattedCurrentDate={formattedCurrentDate}/>
            ))
          ) : (
            filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <SearchItem key={item.EventId} searchItem={item} isEven={index % 2 === 0} formattedCurrentDate={formattedCurrentDate}/>
              ))
            ) : (
              <EmptySearchResult />
            )
          )}
  </div>
</div>

<div
  id="modals"
  className={clsx('modals', smSearchForm ? 'block h-full' : '')}
>
  <span className="closes" onClick={() => setSmSearchForm(false)}>
    X
  </span>
  <div className="modal-contents">
    <div className="hero-search-bar">
      <form className="hero-form">
        <button type="button">
          <StarSvg />
        </button>

        <input
          id="showModalModal"
          type="text"
          placeholder="Zoek een evenement, clubavond of festival"
          autoComplete="off"
          name="search"
          ref={searchMobile}
          onFocus={handleSearchFormFocus}
          onClick={handleSearchFormFocus}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </form>
      <div
      // ref={searchModalBoxRef}
        className={clsx(
          'search-information-box mobile',
          smSearchForm ? 'active' : ''
        )}
      >
        <div className="main-box">
    <h6 className='search-title'>{title}</h6>
    {searchValue === '' ? (
            trendingData.map((item, index) => (
              <SearchItem key={item.EventId} searchItem={item} isEven={index % 2 === 0} />
            ))
          ) : (
            filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <SearchItem key={item.EventId} searchItem={item} isEven={index % 2 === 0} />
              ))
            ) : (
              <EmptySearchResult />
            )
          )}
  </div>
        {/* <div className="main-box">
          <a href="/single-event">
            <div className="search-information-service serach-info">
              <h6>Trending</h6>

              <div className="row">
                <div className="search-information-image">
                  <TrendingCheckSvg />
                </div>

                <div className="search-information-content">
                  <h5>Charlee-Lovelee</h5>
                  <p>8 Juni 23:00 + Gastenlijst</p>
                </div>
              </div>
            </div>
          </a>
          <a href="/single-event">
            <div className="search-information-service">
              <div className="row">
                <div className="search-information-image">
                  <TrendingStarSvg />
                </div>

                <div className="search-information-content">
                  <h5>Freaky-Supperclud</h5>
                  <p>8 Juni 23:00 + Gastenlijst</p>
                </div>
              </div>
            </div>
          </a>
          <a href="/single-event">
            <div className="search-information-service">
              <div className="row">
                <div className="search-information-image">
                  <TrendingCheckSvg />
                </div>

                <div className="search-information-content">
                  <h5>Mimi-Chin Chin</h5>
                  <p>8 Juni 23:00 + Gastenlijst</p>
                </div>
              </div>
            </div>
          </a>
          <a href="/single-event">
            <div className="search-information-service social-club-information">
              <div className="row">
                <div className="search-information-image">
                  <TrendingStarSvg />
                </div>
                <div className="search-information-content">
                  <h5>Super Social-Chicago Social Club</h5>
                  <p>8 Juni 23:00 + Vriendenligst</p>
                </div>
              </div>
            </div>
          </a>
        </div> */}
      </div>
    </div>
  </div>
</div>
</>
  )
      }

export default SearchBar;