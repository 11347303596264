import { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
import { Checkbox, Input, effect } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { LogoSvg } from '../../utils/svg';
import ArrowImg from '../../assets/image svg/arrow-left.svg';
import LoodsImg from '../../assets/image svg/Loods1.svg';
import LocateImg from '../../assets/image svg/Locatie.svg';
import CalendarImg from '../../assets/image svg/Calendar-N.svg';
import GuestListImg from '../../assets/image svg/Guestlist.svg';
import UserImg from '../../assets/image svg/User.svg';
import MusicImg from '../../assets/image svg/Music.svg';
import GuestListSinImgs from '../../assets/image svg/Guestlist-sin1.svg';
import GuestListSinImg from '../../assets/image svg/Guestlist-sin.svg';
import UserSinImg from '../../assets/image svg/User-sin.svg';
import WarningImg from '../../assets/image svg/Warning.svg';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import { NavLink } from 'react-router-dom';
import copy from '../../assets/images/copy-svgrepo-com.svg'

// import timezone from 'dayjs/plugin/timezone';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import 'flatpickr/dist/themes/dark.css';
// import Flatpickr from 'react-flatpickr';
// import { Dutch } from 'flatpickr/dist/l10n/nl.js';

import './SingleEvent.css';
import { signUpEventAPI } from '../../utils/config';
dayjs.extend(utc);
// dayjs.extend(timezone);
// Flatpickr.localize(Dutch);

const SingleEvent = ({ token }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const textRef = useRef(null);
  const [eventData, setEventData] = useState([]);
  const [soldOutStatus, setSoldOutStatus] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [eFormData, setEFormData] = useState({
    Firstname: '',
    Lastname: '',
    Email: '',
    dob: '',
    gender: '',
    event: '',
    event_id: '',
    token: token,
  });
  const [eventLink, setEventLink] = useState('');
  const [dobPlaceHolder, setDobPlaceHolder] = useState('Geboortedatum');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  console.log('copy', copy)
  // const [dobPlaceHolder, setDobPlaceHolder] = useState('Geboortedatum'); //wtf?
  useEffect(() => {
    if (location.state !== null) {
      const storage = JSON.parse(localStorage.getItem('allEvents'));
      const getPageData = storage.filter(
        item => item.EventId === location.state
      );

      setEventData(getPageData[0]);
      const soldOutText = getPageData[0].OutofStock['sold-out'];
      // console.log('getPageData[0]', getPageData[0].TicketLink)
      const ticketLink = getPageData[0].TicketLink || '';
      // const ticketUrl = getPageData[0].OutofStock['sold-out'];
      if (soldOutText === true || soldOutText === 'true') {
        setSoldOutStatus(true);
      }
      if (ticketLink !== '') {
        if (ticketLink.includes('http://localhost/')) {
          const modifiedLink = ticketLink.replace('http://localhost/', '');
          setEventLink(modifiedLink);
        } else {
          setEventLink(ticketLink);
        }
      }
      setEFormData({
        ...eFormData,
        event: getPageData[0].EvenName,
        event_id: getPageData[0].EventId,
      });
    } else {
      navigate('/');
    }
  }, []);

  const handleCopyClick = () => {
    if (textRef.current) {
      const textToCopy = textRef.current.innerText;
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          // setCouponCode('Copied!')
          textRef.current.innerText = 'Copied!'
          textRef.current.style.color = 'green'
        })
        .catch((error) => {
          console.error('Error copying text:', error);
        });
    }
  };

  console.log('eventLink', eventLink);
  const handleBackClick = e => {
    e.preventDefault();
    navigate(`/`);
  };

  const onChange = e => {
    setEFormData({ ...eFormData, [e.target.name]: e.target.value });

    switch (e.target.name) {
      case 'Firstname':
        if (formErrors.hasOwnProperty('Firstname') && formErrors.Firstname) {
          setFormErrors({
            ...formErrors,
            Firstname: !validateName(eFormData.Firstname),
          });
        }
        break;

      case 'Lastname':
        if (formErrors.hasOwnProperty('Lastname') && formErrors.Lastname) {
          setFormErrors({
            ...formErrors,
            Lastname: !validateName(eFormData.Lastname),
          });
        }
        break;
      case 'Email':
        if (formErrors.hasOwnProperty('Email') && formErrors.Email) {
          setFormErrors({
            ...formErrors,
            Email: !validateEmail(eFormData.Email),
          });
        }
        break;
      case 'gender':
        if (formErrors.hasOwnProperty('gender') && formErrors.gender) {
          setFormErrors({
            ...formErrors,
            gender: ['Man', 'Vrouw', 'Onzijdig'].includes(eFormData.gender),
          });
        }
        break;
      case 'agreement':
        setFormErrors({ ...formErrors, agreement: !e.target.value });
        break;
      case 'dob':
        setFormErrors({ ...formErrors, dob: !validateDob(eFormData.dob) });

      default:
        break;
    }
  };

  const getThanks = () => {
    navigate(`/thanks?eventId=${eventData.EventId}`, {
      state: eventData.EventId,
    });
  };

  const focusDob = () => {
    setDobPlaceHolder('dd-mm-jjjj');
  };

  const blurDob = () => {
    setDobPlaceHolder('Geboortedatum');
  };

  const extractAge = (date) => {

    const [day, month, year] = date.split('-');
    const birthDate = new Date(`${year}-${month}-${day}`);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    // console.log('AGE', age)
    return age;
  }

  const changeDob = (e) => {
    const value = e.target.value;
    let formattedValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    let placeholder = 'dd-mm-jjjj';
    let cursorPosition = formattedValue.length;
    const key = e.nativeEvent.inputType;
  
    const padWithZero = (number) => {
      return number.toString().padStart(2, '0');
    };
  
    // Format day (dd)
    if (formattedValue.length >= 2) {
      let day = parseInt(formattedValue.slice(0, 2), 10);
  
      if (day < 1) {
        day = 1;
      } else if (day > 31) {
        day = 31;
      }
  
      formattedValue =
        padWithZero(day) + '-' + formattedValue.slice(2);
      placeholder = formattedValue + placeholder.slice(3);
      cursorPosition = 3;
    }
  
    // Format month (mm)
    if (formattedValue.length >= 5) {
      let month = parseInt(formattedValue.slice(3, 5), 10);
  
      if (month < 1) {
        month = 1;
      } else if (month > 12) {
        month = 12;
      }
  
      formattedValue =
        formattedValue.slice(0, 3) +
        padWithZero(month) +
        '-' +
        formattedValue.slice(5);
      placeholder = placeholder.slice(0, 3) + '-mm-' + placeholder.slice(5);
      cursorPosition = 6;
    }
  
    // Format year (jjjj)
    if (formattedValue.length >= 10) {
      let year = parseInt(formattedValue.slice(6, 10), 10);
  
      const minYear = 1930;
      const maxYear = 2023;
  
      if (year < minYear) {
        year = minYear;
      } else if (year > maxYear) {
        year = maxYear;
      }
  
      formattedValue =
        formattedValue.slice(0, 6) + year.toString().padStart(4, '0');
  
      const age = extractAge(formattedValue);
      if (age < +eventData.MinimalAge) {
        setBtnDisabled(true);
      } else {
        setBtnDisabled(false);
      }
    }
  
    if (
      key === 'deleteContentBackward' &&
      (formattedValue.length === 6 || formattedValue.length === 3)
    ) {
      formattedValue =
        formattedValue.slice(0, cursorPosition - 1) +
        formattedValue.slice(cursorPosition);
      cursorPosition -= 1;
    }
  
    setEFormData({ ...eFormData, dob: formattedValue });
    setDobPlaceHolder(placeholder);
  };
  

  // const changeDob = e => {
  //   const value = e.target.value;
  //   let formattedValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  //   let placeholder = 'dd-mm-jjjj';
  //   let cursorPosition = formattedValue.length;
  //   const key = e.nativeEvent.inputType;

  //   // Format day (dd)
  //   if (formattedValue.length >= 2) {
  //     let day = parseInt(formattedValue.slice(0, 2), 10);

  //     if (day < 1) {
  //       day = 1;
  //     } else if (day > 31) {
  //       day = 31;
  //     }

  //     formattedValue =
  //       day.toString().padStart(2, '0') + '-' + formattedValue.slice(2);
  //     placeholder = formattedValue + placeholder.slice(3);
  //     cursorPosition = 3;
  //   }

  //   // Format month (mm)
  //   if (formattedValue.length >= 5) {
  //     let month = parseInt(formattedValue.slice(3, 5), 10);

  //     if (month < 1) {
  //       month = 1;
  //     } else if (month > 12) {
  //       month = 12;
  //     }

  //     formattedValue =
  //       formattedValue.slice(0, 3) +
  //       month.toString().padStart(2, '0') +
  //       '-' +
  //       formattedValue.slice(5);
  //     placeholder = placeholder.slice(0, 3) + '-mm-' + placeholder.slice(5);
  //     cursorPosition = 6;
  //   }

  //   // Format year (jjjj)
  //   if (formattedValue.length >= 10) {
  //     let year = parseInt(formattedValue.slice(6, 10), 10);

  //     const minYear = 1930;
  //     const maxYear = 2023;

  //     if (year < minYear) {
  //       year = minYear;
  //     } else if (year > maxYear) {
  //       year = maxYear;
  //     }

  //     formattedValue =
  //       formattedValue.slice(0, 6) + year.toString().padStart(4, '0');

  //     const age = extractAge(formattedValue);
  //     if (age < +eventData.MinimalAge) {
  //       setBtnDisabled(true);
  //       // console.log('btnDisabled:', btnDisabled)
  //     } else {
  //       setBtnDisabled(false);
  //     }
  //   }

  //   if (
  //     key === 'deleteContentBackward' &&
  //     (formattedValue.length === 6 || formattedValue.length === 3)
  //   ) {
  //     formattedValue =
  //       formattedValue.slice(0, cursorPosition - 1) +
  //       formattedValue.slice(cursorPosition);
  //     cursorPosition -= 1;
  //   }

  //   setEFormData({ ...eFormData, dob: formattedValue });
  //   setDobPlaceHolder(placeholder);

  //   // const input = document.getElementById('date');
  //   // input.setSelectionRange(cursorPosition, cursorPosition);
  // };

  async function sendEventData(dataObject) {
    // console.log('eventForm Data', eFormData);
    try {
      const res = await fetch(`${signUpEventAPI}`, {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(dataObject),
      });
      const response = await res.json();
      console.log('create post request--->', response);
      // if (response.status) {
      //   window.alert(response.msg);
      // }
      return response;
    } catch (err) {
      console.error('Error===>', err);
    }
  }
  // console.log('DATA:', eventData)

  const validateName = name => {
    return /^[A-Za-z]{3,}$/.test(name);
  };

  const validateEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateDob = dob => {
    if (dob.length < 10) {
      return false;
    } else {
      return true;
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!validateName(eFormData.Firstname)) {
      errors.Firstname = true;
      isValid = false;
    }

    if (!validateName(eFormData.Lastname)) {
      errors.Lastname = true;
      isValid = false;
    }

    if (!validateEmail(eFormData.Email)) {
      errors.Email = true;
      isValid = false;
    }

    if (eFormData.dob.length < 10) {
      errors.dob = true;
      isValid = false;
    }

    if (!['Man', 'Vrouw', 'Onzijdig'].includes(eFormData.gender)) {
      errors.gender = true;
      isValid = false;
    }

    if (!eFormData.agreement) {
      errors.agreement = true;
      isValid = false;
    }

    setFormErrors(errors);
    setFormValid(isValid);

    return isValid;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (validateForm()) {
      // console.log('eFormData:', eFormData);
      const { agreement, ...dataToSend } = eFormData;
      console.log('eventLink', eventLink);
      const response = await sendEventData(dataToSend);
      // console.log('response status', response.status)
      if (response.status) {
        if (eventCategory === 'Community ticket') {
          window.open(eventLink, '_blank');
        }
        getThanks();
      }
    } else {
      console.error('Form validation Error');
    }
  };

  if (eventData.length < 1) {
    return (
      <div className="hero-section-single event-page" style={{ minHeight: '100dvh' }}>
        <div className="container-single">
          <div className="row">
            <div className="arrow">
              <a href="/" onClick={handleBackClick}>
                <img src={ArrowImg} alt="arrow" className="logo" />
              </a>
            </div>
            <div className="logo-Img">
              <NavLink to="/">
                <LogoSvg />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  const eventImage = eventData.EvenementImages;
  const eventTitle = eventData.EvenName.charAt(0) + eventData.EvenName.slice(1);

  const eventClub =
    eventData.TheClub.charAt(0).toUpperCase() +
    eventData.TheClub.slice(1).toLowerCase();
  const eventCity = eventData.CityName;
  const eventDate = dayjs
    .unix(eventData.EventDate)
    .utc()
    .locale('nl')
    .format('D MMM');
  const eventTime = dayjs
    .unix(eventData.EventDate)
    .utc()
    .locale('nl')
    .format('HH:mm');
  const eventCategory = eventData.EventCategorie;
  const eventAge = eventData.MinimalAge;
  const eventGenres = eventData.GenreList;
  const eventOriginalPrice = eventData.OriginalPrice;
  const eventOurPrice = eventData.OurPrice;
  const eventPriceLabel = 'in plaats van ' || ''; // eventData.price_label
  const coupon = eventData.Coupon_code;
  // setCouponCode(coupon);

  const description =
    eventCategory !== 'Community ticket'
      ? 'We zetten jouw naam op de gastenlijst, zodat je gratis of goedkoper naar binnen kan.'
      : 'Met een community ticket verzekeren wij jou van het goedkoopste kaartje.';
  const btnText =
    eventCategory !== 'Community ticket'
      ? 'Zet me op de lijst'
      : 'Koop community ticket';

  const priceText =
    eventCategory !== 'Community ticket'
      ? `${eventPriceLabel} €${eventOriginalPrice},-`
      : `${eventPriceLabel} €${eventOriginalPrice},-`;
  const eventPriceClass =
    soldOutStatus === true
      ? 'soldout row justify-content-between'
      : 'row justify-content-between';




  return (
    <div>
      <div className="hero-section-single event-page">
        <div className="container-single">
          <div className="row">
            <div className="arrow">
              <a href="/" onClick={handleBackClick}>
                <img src={ArrowImg} alt="arrow" className="logo" />
              </a>
            </div>
            <div className="logo-Img">
              <NavLink to="/">
                <LogoSvg />
              </NavLink>
            </div>
          </div>
        </div>

        <div className="custom-container containers">
          <div className="event-img text-center">
            <img src={eventImage} alt="Loods1" />
          </div>
          <div className="event-heading">
            <h1 className="text-center text-whites">{eventTitle}</h1>
          </div>
          <div className="event-information-box">
            <ul className="row gap">
              <li className="event date">
                <a className="text-white flex">
                  <img src={LocateImg} alt="Locatie" />
                  <span className="text-color ml-1">
                    {eventClub}, {eventCity}
                  </span>
                </a>
              </li>

              <li className="event date">
                <a className="text-white flex">
                  <img src={CalendarImg} alt="Calendar" />
                  <span className="text-color ml-1">
                    {eventDate} — {eventTime}
                  </span>
                </a>
              </li>

              <li className="event date">
                <a className="text-white flex">
                  <img src={GuestListImg} alt="Guestlist" />
                  <span className="text-color ml-1">{eventCategory}</span>
                </a>
              </li>
              <li className="event date">
                <a className="text-white flex">
                  <img src={UserImg} alt="User" />
                  <span className="text-color ml-1">{eventAge}+</span>
                </a>
              </li>
              <li className="event date">
                <a className="text-white flex">
                  <img src={MusicImg} alt="Music" />
                  <span className="text-color ml-1">{eventGenres}</span>
                </a>
              </li>

            </ul>
          </div>

          <div className="event-price-box first ">
            <div className={eventPriceClass}>
              <div className="price-box text-white">
                <h3>
                  {soldOutStatus === true
                    ? 'This event is sold out'
                    : `€${eventOurPrice},-`}
                </h3>
                <h6>{soldOutStatus === true ? '' : priceText}</h6>
                {/* <p>{soldOutStatus === true ? '' : eventPriceLabel}</p> */}
                {/* <h6>in plaats van €{eventOriginalPrice},-</h6> */}
              </div>

              <div className="price-button">
                <button
                  className="button"
                  onClick={handleSubmit}
                  disabled={(soldOutStatus === true || btnDisabled === true) ? 'disabled' : ''}
                >
                  {btnText}
                  {/* Koop vriendenticket */}
                </button>
              </div>
            </div>
          </div>

          <div className="events-box">
            {coupon && (
              <div className="multipan-event-box vriendenticket">
                <div className="events-image">
                  <img src={GuestListSinImgs} alt="guest-list-sin" />
                </div>
                <div className="events-content">
                  <h5 className="text-white">Let op; bij dit event krijg je korting na het invoeren van een code</h5>
                  <p className="text-light-gray event-content event-code">De code is :
                    <span className='event-code__span'>
                      <img className='copy-icon' src={copy} alt="" />
                      <span ref={textRef}
                        onClick={handleCopyClick}
                      >
                        {coupon} 
                      </span>
                    </span></p>
                </div>
              </div>
            )}
            <div className="multipan-event-box vriendenticket">
              <div className="events-image">
                <img src={GuestListSinImg} alt="guest-list-sin" />
              </div>
              <div className="events-content">
                <h5 className="text-white">{eventCategory}</h5>
                <p className="text-light-gray event-content">
                  {description}</p>
              </div>
            </div>

            <div className="multipan-event-box">
              <div className="events-image">
                <img src={UserSinImg} alt="user-sin" />
              </div>
              <div className="events-content">
                <h5 className="text-white">Minimale leeftijd {eventAge}+</h5>
              </div>
            </div>

            <div className="multipan-event-box">
              <div className="events-image">
                <img src={WarningImg} alt="warning" />
              </div>
              <div className="events-content">
                <h5 className="text-white">
                  Ticket refund- en resell niet mogelijk
                </h5>
              </div>
            </div>
          </div>
        </div>

        <section className="event-form-sec">
          <div className="custom-container containers">
            <div className="event-information-form">
              <h3 className="text-white form-heading">Jouw gegevens</h3>
              <form>
                <div className="first-name event-sec-forms">
                  <div className="col-md-6">
                    <Input
                      type="text"
                      placeholder="Voornaam"
                      name="Firstname"
                      value={eFormData.Firstname}
                      className={
                        formErrors.Firstname ? 'sin error-field' : 'sin'
                      }
                      onInput={onChange}
                    // autoComplete='off'
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      placeholder="Achternaam"
                      name="Lastname"
                      value={eFormData.Lastname}
                      className={
                        formErrors.Lastname ? 'sin error-field' : 'sin'
                      }
                      // autoComplete='off'
                      onInput={onChange}
                    />
                  </div>
                </div>
                <div className="email event-sec-form">
                  <Input
                    type="email"
                    name="Email"
                    id="email"
                    value={eFormData.Email}
                    placeholder="E-mail"
                    className={formErrors.Email ? 'sin error-field' : 'sin'}
                    // autoComplete='off'
                    onInput={onChange}
                  />
                </div>
                <div
                  className={
                    formErrors.dob
                      ? 'date event-sec-form error-field'
                      : 'date event-sec-form'
                  }
                >
                  <Input
                    type="text"
                    maxLength="10"
                    id="date"
                    name="dob"
                    value={eFormData.dob}
                    inputMode="numeric"
                    className={formErrors.dob ? 'sin error-field' : 'sin'}
                    placeholder={dobPlaceHolder}
                    onFocus={focusDob}
                    onBlur={blurDob}
                    onChange={changeDob}
                    autoComplete='off'
                  />
                  {/* <Flatpickr {...flatpickrProps} /> */}
                </div>

                <div className="geslacht event-sec-form sin">
                  <select
                    id="geslacht"
                    className={
                      formErrors.gender
                        ? 'cx-ui-select error-field'
                        : 'cx-ui-select'
                    }
                    name="gender"
                    size="1"
                    data-filter="false"
                    data-placeholder=""
                    onChange={onChange}
                  >
                    <option value="gender" disabled selected>
                      Geslacht
                    </option>
                    <option value="Man">Man</option>
                    <option value="Vrouw">Vrouw</option>
                    <option value="Onzijdig">Onzijdig</option>
                  </select>
                </div>

                {/* <div className="hidden-field ">
  <Input
    type="hidden"
    name="token"
    id="token"
    placeholder="token"
  />
  <Input
    type="hidden"
    name="event_id"
    id="event_id"
    placeholder="event_id"
  />
  <Input
    type="hidden"
    name="event"
    id="event"
    placeholder="event"
  />
</div> */}
                <div
                  className={
                    formErrors.agreement ? 'checkbox error-field' : 'checkbox'
                  }
                >
                  <div className="check">
                    <Input
                      type="checkbox"
                      name="agreement"
                      id="agreement"
                      checked={eFormData.agreement}
                      onChange={e => {
                        onChange(e);
                        setEFormData({
                          ...eFormData,
                          agreement: e.target.checked,
                        });
                      }}
                    />
                  </div>
                  <div className="texts">
                    <label className="text-white" htmlFor="agreement">
                      Ik ga akkoord met de <a href="#">algemene voorwaarden</a>
                      <span> van 22NIGHT</span>
                    </label>
                  </div>
                </div>

                <div className="event-price-box destop">
                  <div className={eventPriceClass}>
                    <div className="price-box text-white">
                      <h3>
                        {soldOutStatus === true
                          ? 'This event is sold out'
                          : `€${eventOurPrice},-`}
                      </h3>
                      <h6>{soldOutStatus === true ? '' : priceText}</h6>
                      {/* <p>{soldOutStatus === true ? '' : eventPriceLabel}</p> */}
                    </div>

                    <div className="price-button">
                      <button
                        className="button openPopupButtons"
                        type="button"
                        // onClick={createEvent}
                        onClick={handleSubmit}
                        disabled={(soldOutStatus === true || btnDisabled === true) ? 'disabled' : ''}
                      >
                        {/* Koop vriendenticket */}
                        {btnText}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div id="popups" className="popups">
            <div className="popup-contents">
              <img
                src="assets/image svg/Illustration.svg"
                className="Illustration"
              />
              <p className="Hier">Hier is je community ticket</p>
              <p className="Je">
                Je ontvangt een e-mail van de organisatie zodra de betaling is
                afgerond.
              </p>
              <a href="index.html" className="Terug">
                Terug naar home
              </a>
            </div>
          </div>
        </section>
        <div className="event-price-box mobile">
          <div className={eventPriceClass}>
            <div className="price-box text-white">
              <h3>
                {soldOutStatus === true
                  ? 'This event is sold out'
                  : `€${eventOurPrice},-`}
              </h3>
              <h6>{soldOutStatus === true ? '' : priceText}</h6>
              {/* <p>{soldOutStatus === true ? '' : eventPriceLabel}</p> */}
            </div>

            <div className="price-button">
              <button
                className="button openPopupButtons"
                type="button"
                onClick={handleSubmit}
                disabled={(soldOutStatus === true || btnDisabled === true) ? 'disabled' : ''}
              >
                {/* Koop vriendenticket */}
                {btnText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleEvent;
