import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { subscribeEventAPI } from '../../utils/config';
import { subscribeEmail } from '../../utils/config';
import axios from 'axios';

const Footer = ({ token }) => {

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);


  async function sendEmail(userObj) {
    try {
      const response = await axios.post(`${subscribeEmail}`, userObj, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        // body: userObj,
        // body: JSON.stringify(userObj),
      });
      // const resData = await response.json();
      console.log(
        'sendEmailData:', response
      )

      if (response.data.status) {
        setIsSubscribed(true)
        setEmailErrorMessage("")
        setIsEmailValid(true)
        setEmail('')
      } else if (response.status === 200) {
        setEmailErrorMessage(response.data.msg)
        setEmail('')
      }
      return response
    } catch (err) {
      console.error('Error===>', err);
    }
  }

  
  const onBlur = () => {
    setIsEmailValid(true)
    setIsSubscribed(true)
  }

  const inputHandler = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    setEmail(e.target.value)
    if (!isEmailValid) {
      setIsEmailValid(isValidEmail)
    }

  }

  const validateEmail = async (e) => {
    e.preventDefault()
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    if (isValidEmail) {
      setIsEmailValid(true)
      // console.log('updatedSubscribeObj', updatedSubscribeObj)
      // setSubscribeObj(updatedSubscribeObj);

      const response = await sendEmail({ Email: email })
      console.log('response', response.data)
      if (response?.data?.status) {

      }

      // setEmailErrorMessage()

    } else {
      setIsEmailValid(false)
    }
  }
  const onOutsideInputClick = (e) => {
    if(!e.target.closest('.input-wrapper')){
      setIsEmailValid(true)
      setIsSubscribed(false)
    }
  }

  return (
    <Box className="">
      <footer
      onClick={onOutsideInputClick}
      onMouseLeave={() => {
        console.log('onMouseLeave')
        setEmailErrorMessage(false)
        setIsEmailValid(true)
        setIsSubscribed(false)
        setEmail('')
      }}
      >
        <div className="custom-container">
          <div className="footer-top">
            <div className="row">
              <div className="newsletter">
                <h3 className="join-newsletter">Join our newsletter</h3>
                <h6 className="text-gray">
                  Every party — straight to your inbox.
                </h6>
              </div>

              <div className="newsletter-form">
                <form className='email-form' onSubmit={validateEmail}>
                  <span className='input-wrapper'>
                    <input className={
                      `${isEmailValid ? "" : "error-field"}
                      ${isSubscribed ? "success-field" : ""}
                      `

                    }
                      onBlur={() => setIsEmailValid(true)}
                      value={email} onChange={inputHandler}
                      type="text" placeholder="Enter your e-mail" />
                  </span>
                  <button type="submit">Subscribe</button>
                  <p className='email-error'>{emailErrorMessage}</p>
                </form>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="row">
              <div className="footer-service">
                <nav>
                  <ul className="row gap">
                    <li>
                      <a href="https://react22nights.coderwolves.com/Terms_PDF/Algemene_Voorwaarden_22Night_bv.docx.pdf" target="_blank" rel="noopener noreferrer" className="text-gray">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="https://react22nights.coderwolves.com/Terms_PDF/Algemene_Voorwaarden_22Night_bv.docx.pdf" target="_blank" rel="noopener noreferrer" className="text-gray" >
                        Terms of Conditions
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="footer-description">
                <h6 className="text-end text-gray">
                  22NIGHTS BV. All rights reserved.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Box>
  );
};

export default Footer;
