import {
  LogoSvg,
} from '../utils/svg';
import { NavLink } from 'react-router-dom';
const Logo = () => {



  return (
    <div className="logo-img flex justify-center">
        {/* <NavLink to="/"> */}
          <LogoSvg />
        {/* </NavLink> */}
      </div>
  )
}

export default Logo;