import {
  TrendingCheckSvg,
  TrendingStarSvg,
} from '../utils/svg';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import { adjustTime } from '../helpers/helpers';

import { useNavigate } from 'react-router-dom';
dayjs.extend(utc);
dayjs.extend(timezone);

const SearchItem = ({ searchItem, isEven,formattedCurrentDate }) => {
  const navigate = useNavigate();
  const formattedDate = dayjs.unix(searchItem.EventDate).utc().locale('nl').format('D MMMM HH:mm');

  const handleItemClick = () => {
    // if (searchItem.OutofStock['sold-out'] !== true && searchItem.OutofStock['sold-out'] !== "true") {
      console.log('searchItem', searchItem)
      const registerFrom = adjustTime(searchItem.RegistrationFrom)

    if (formattedCurrentDate < registerFrom) return
    navigate(`/single-event?eventId=${searchItem.EventId}`, {
      state: searchItem.EventId,
    });
    // } 
    // return null;

  };

  const eventName = searchItem.EvenName.split(/[\s-]+/)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  return (
    <a onClick={handleItemClick}>
      <div className="search-information-service serach-info">

        <div className="row">
          <div className="search-information-image">
            {isEven ? <TrendingCheckSvg /> : <TrendingStarSvg />}
          </div>

          <div className="search-information-content">
            <h5>{eventName}</h5>
            <p>{formattedDate} &bull; {searchItem.EventCategorie}</p>
          </div>
        </div>

      </div>
    </a>
  )
}

export default SearchItem;