// export const API_URL = 'https://cors-anywhere.herokuapp.com/https://wp22nights.handsomebaba.com/22Night_API/';
// const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '192.168.0.49';
/* Vivek
const isLocalhost = window.location.hostname === 'localhost';
const PROXI = 'https://corsproxy.io/?';
const useProxy = isLocalhost ? PROXI : '';

export const API_URL = useProxy + 'https://staging3.22night.com/22Night_API/';
export const signUpEventAPI = useProxy + 'https://staging3.22night.com/22Night_API/create.php';
export const subscribeEventAPI = useProxy + 'https://staging3.22night.com/22Night_API/binnenkort.php';
export const subscribeEmail = useProxy + 'https://staging3.22night.com/22Night_API/emailSend.php';
*/

export const API_URL = 'https://corsproxy.io/?https://staging3.22night.com/22Night_API/';
export const signUpEventAPI = 'https://corsproxy.io/?https://staging3.22night.com/22Night_API/create.php';
export const subscribeEventAPI = 'https://corsproxy.io/?https://staging3.22night.com/22Night_API/binnenkort.php';
export const subscribeEmail = 'https://corsproxy.io/?https://staging3.22night.com/22Night_API/emailSend.php';



//2nd API-- https://staging3.22night.com/22Night_API/create.php

// 3rd API- https://staging3.22night.com/22Night_API/binnenkort.php